.well {
	background-color: #333;
}

.stat-label {
	color: #ccc;
	text-transform: uppercase;
	font-weight: bold;
}

.stat-value {
	color: #fff;
	font-weight: bold;
	font-size: 3em;
}
