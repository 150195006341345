.form-control-inline {
  display: inline-block;
  width: unset;
  max-width: 100%;
}

.form-group-inline {
  display: inline-block;
}

.form-group-inline label {
  font-weight: normal;
  margin: 0px 5px;
}

hr.min-height {
  margin-top: 5px;
  margin-bottom: 5px;
}

.blue {
  color: blue !important;
}

.ck-editor__editable {
  min-height: 350px;
}

.inline-ck-editor .ck-editor__editable {
  min-height: 10px;
}

.hidden {
  display: none;
}

.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eee;
}

.navbar .navbar-brand .navbar-logo {
  top: 0;
  width: 230px;
}

.navbar.bg-dark .navbar-brand .navbar-logo {
  top: 0;
  width: 43px;
}

.navbar .navbar-brand-label {
  vertical-align: middle;
}

.navbar-light .navbar-text a {
  color: rgba(0,0,0,.5);
}

.navbar-light .navbar-text a.active {
  color: rgba(0,0,0,.9);
}

.dark-card {
  background-color: rgb(51, 51, 51);
  border-color: rgb(51, 51, 51);
}

.dropdown-item a {
  color: #000000;
}

.dropdown-item a:hover {
  text-decoration: none;
}

.footer {
  margin-top: 50px;
  color: #999;
}

.footer .container {
  border-top: 1px solid #ccc;
  padding: 20px 15px;
}

.footer .links li {
  display: inline-block;
  padding-right: 10px;
  margin-right: 10px;
  list-style: none;
  border-right: 1px dotted #999;
}

.footer .links li:last-child {
  padding-right: 0;
  margin-right: 0;
  border: none;
}

.footer .links {
  margin: 0;
  padding: 0;
}

.footer a, .footer a:visited,
.footer .btn-link {
  color: #999;
}

.accordion .card {
  border: none;
  margin-bottom: 10px;
}

.accordion .card-header {
  border: solid 1px #ececec;
  background-color: #f9f9f9;
  padding: 0;
}

.accordion {
  color: #546a79;
}

.accordion h3 {
  font-size: 24px;
}

.accordion h3 button::before {
  content: url(/media/icon-expand-more.svg);
  display: inline-block;
  vertical-align: middle;
  padding-left: 7px;
  padding-right: 7px;
  transition: all .3s ease-in-out;
}

.accordion .card-header h3 .btn-link {
  font-size: 20px;
  color: #546a79;
  padding: 5px;
  word-break: break-word;
  white-space: inherit;
  text-align: left;
}

.accordion .card-header h3 .btn-link:hover {
  text-decoration: none;
}

.accordion p,
.accordion ul {
  font-size: 16px;
  line-height: 2.19;
  color: #546a79;
}

.accordion a,
.news a {
  color: #4a90e2;
  font-weight: bold;
}

.news h2 {
  font-size: 24px;
  line-height: 1.42;
}

.news img {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

.news .post-time {
  font-size: 18px;
  line-height: 1.22;
  color: #546a79;
}

.accordion img,
.news img {
  position: relative;
  display: table;
  clear: both;
  text-align: center;
  margin: 1em auto;
}

.accordion .image-style-side,
.news .image-style-side {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}

.has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

.has-error .control-label,
.has-error .help-block {
  color: #a94442;
}

.ck .ck-balloon-panel {
  z-index: 1200;
}

button:focus {
  outline: none;
}

.personnel-1,
.personnel-2 {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  min-height: 100vh;
}

.personnel-2 {
  background-position-y: center;
}

.personnel-1,
.resume-container {
  background-image: url(/media/personnel-bg-1.jpg);
}

.resume-container {
  background-attachment: fixed;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  min-height: 100vh;
}

.personnel-2 {
  background-image: url(/media/personnel-bg-3.jpg);
}

.personnel-nav-1 {
  background-color: rgba(255, 255, 255, 0.8);
}

.personnel-nav-2 {
  background-color: #ffffff;
}

.personnel-form {
  margin: auto;
  padding: 40px 31px 40px 35px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.35);
  transform: translateY(-50%);
}

.personnel-1 .personnel-form {
  top: 50vh;
}

.resume-container .resume-form {
  margin: auto;
  padding: 40px 31px 40px 35px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.6);
  margin-top: 100px;
}

.personnel-2 .personnel-form {
  top: 80vh;
}

.personnel-2 .personnel-form p {
  text-align: center;
}

.personnel-form h3,
.resume-form h3 {
  text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);
  font-size: 42px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 5px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
}

.resume-form h3 {
  font-size: 36px;
  letter-spacing: 2px;
}

.resume-form h4 {
  font-size: 21px;
  color: #ffffff;
}

.personnel-form p,
.resume-form p,
.resume-form li {
  text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}

.resume-form li {
  list-style: disc;
}

.btn-personnel {
  border-radius: 25px;
  border: solid 1px #ffffff;
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  padding: 12px 58px;
  margin: 0 auto;
  display: block;
}

.btn-personnel:hover {
  color: #000000;
  background-color: #ffffff;
}

.personnel-1 .form-control,
.personnel-2 .form-control {
  max-width: 337px;
  margin: 0 auto;
}

.personnel-1 .form-group .control-label,
.personnel-2 .form-group .control-label {
  display: none;
}

.personnel-1 .copyright-gird,
.personnel-2 .copyright-gird {
  bottom: 40px;
  position: absolute;
}

.personnel-2 .copyright-gird {
  bottom: 12px;
}

.personnel-1 .copyright,
.personnel-2 .copyright,
.resume-container .copyright {
  color: #ffffff;
  font-size: 14px;
}

.personnel-hero {
  position: relative;
  margin-top: 56px;
}

.personnel-hero .main-img {
  height: 250px;
  width: 100%;
  background-size: 120vw;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
  background-image: url(/media/personnel-keyimg.png);
}

.personnel-hero .content {
  position: absolute;
  bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  top: 52px;
  left: 60px;
  right: 60px;
  text-align: left;
}

.personnel-footer {
  border: none;
  background-color: #546a79;
}

.personnel-footer .copyright {
  color: #ffffff;
  font-size: 14px;
}

.btn.close {
  width: 30px;
  height: 30px;
  padding: 0;
  position: absolute;
  right: 4px;
  top: 4px;
  color: #6c757d;
  font-size: 16px;
}

@media only screen and (min-width: 1280px) {
  .modal-dialog {
    max-width: 1000px;
  }
}

@media only screen and (min-width: 1450px) {
  .personnel-hero .main-img {
    background-size: 100vw;
  }
}

@media only screen and (max-width: 1199px) {
  .personnel-hero .main-img {
    background-size: cover;
  }

  .personnel-1,
  .personnel-2 {
    background-size: 200vh;
    background-position-x: 50%;
    min-height: calc(56px + 100vh);
  }

  .personnel-form {
    top: calc(56px + 50vh);
  }
}

@media only screen and (max-width: 767px) {
  .personnel-hero .content {
    left: 15px;
    right: 15px;
  }

  .personnel-hero .content h1 {
    font-size: 32px;
    text-align: center;
  }

  .personnel-1 h3,
  .personnel-2 h3 {
    font-size: 25px;
  }

  .personnel-1 .copyright-gird,
  .personnel-2 .copyright-gird {
    bottom: -50px;
    text-align: center;
  }
}